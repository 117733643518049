import React from "react";
import Team from "components/homeSections/Team";
import {lang} from "@lang/langT";
import { useRouter } from "next/router";

const Teams = () => {
  const router = useRouter();
  const t = lang(router.locale);
  return (
    <section className=" bg-black py-24 ">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center">
              <span className="mb-2 block text-4xl font-semibold text-primary">
                {t.teamsPage.ourTeam}
              </span>
              <h2
                className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                {t.teamsPage.ourAwesomeTeam}
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
                {t.teamsPage.desc}
              </p>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap justify-center">
          <Team
            name="Erce Arslan"
            // profession="Backend Developer"
            imageSrc="/teams/erce.jpeg"
            resumeKey="erce_arslan"
          />
          <Team
            name="Emre İşlek"
            // profession="Frontend Developer"
            imageSrc="/teams/emre.jpeg"
            resumeKey="emre_islek"
          />
           <Team
            name="Ruslan Petrov"
            // profession="UX Designer"
            imageSrc="/teams/ruslan.jpeg"
            resumeKey="ruslan_petrov"
          />
          <Team
            name="Sergen Kaya"
            // profession="UI Designer"
            imageSrc="/teams/sergen.jpeg"
            resumeKey="sergen_kaya"
          />
          <Team
            name="Koray Süküt"
            // profession="UX Designer"
            imageSrc="/teams/koray.jpeg"
            resumeKey="koray_sukut"
          />
          <Team
            name="Kağan Sarı"
            // profession="UX Designer"
            imageSrc="/teams/kagan.jpeg"
            resumeKey="kagan_sari"
          />
           <Team
            name="Barış Şahin"
            // profession="UX Designer"
            imageSrc="/teams/baris.jpeg"
            resumeKey="baris_sahin"
          />
        </div>
      </div>
    </section>
  );
};

export default Teams;


